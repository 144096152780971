import {FC, useCallback, useMemo} from "react";
import {
    FormOpt,
    LoadingFormReturnProps,
    OnSubmit,
    OuterSubmitFormProps,
    OuterSubmitFormReturnProps,
    saveIdEntity,
    useOuterSubmitForm
} from "../props";
import {FieldArray, Formik, FormikValues} from "formik";
import {clearNumberInput} from "../../props/helpers";
import {
    BusinessCaseItemRequest,
    businessCaseItemSchemaObject,
    ItemType,
    OfferItemRequest,
    TradeItemRequest,
    tradeItemSchemaObject
} from "../../props/apiRequests";
import {Box, css, Grid, styled, Typography} from "@mui/material";
import TradeItemFormBase from "./TradeItemFormBase";
import {
    BusinessCaseItemResponse,
    CurrencyExchangeRateResponse,
    CurrencyResponse,
    OfferItemResponse,
    TradeItemResponse
} from "../../props/apiResponses";
import FormWithoutButton from "../FormWithoutButton";
import {getProductName} from "../../pages/product/props";
import {useBusinessCaseItemManipulator} from "../../services/businessCase/BusinessCaseItemService";
import {convertFromPercentage, convertToPercentage} from "../fields/NumberTextField";
import {useOfferItemManipulator} from "../../services/offer/OfferItemService";
import {Add, Delete} from "@mui/icons-material";
import {priceWithCurrency} from "../../pages/trade/props";
import Button from "../../common/button/Button";
import {DeliveryNoteResponse} from "../../containers/trade/businessCase/schema/deliveryNote";
import TradeItemUnit from "../../containers/trade/schema/TradeItemUnit";

export interface TradeItemFormValues extends FormikValues, Omit<TradeItemRequest, "order"> {
    priceBeforeDiscount: number;
    isDiscountInPercentage: boolean;
    isAlternative: boolean;
}

export interface BusinessCaseItemFormValues extends TradeItemFormValues, Omit<BusinessCaseItemRequest, "order"> {
    isPurchaseDiscountInPercentage?: boolean;
}

export interface OfferItemFormValues extends TradeItemFormValues, Omit<OfferItemRequest, "order"> {

}

interface Props extends OuterSubmitFormProps<TradeItemFormValues> {
    isOffer: boolean;
    currenciesRates: CurrencyExchangeRateResponse[];
    currentCurrency: CurrencyResponse | undefined;
    purchaseCurrency: CurrencyResponse | undefined;
    orderToSupplier: boolean;
    invoice: boolean;
    entity?: TradeItemResponse;
    disabled?: boolean;
    ignoreVat?: boolean;
    deliveryNotes?: DeliveryNoteResponse[];
}
const TradeItemForm: FC<Props> = (
    {
        formikRef,
        onSubmit,
        isOffer,
        currenciesRates,
        currentCurrency,
        orderToSupplier,
        entity,
        loading,
        purchaseCurrency,
        invoice,
        disabled,
        ignoreVat,
        deliveryNotes
    }
) => {
    const commonInitValues = useCallback((item?: Partial<TradeItemResponse>, isSubItem?: boolean): TradeItemFormValues => ({
        name: item?.name ?? "",
        amount: item?.amount ?? 1,
        type: item?.type ?? ItemType.STANDARD,
        code: item?.code ?? "",
        discountPercentage: !!item?.discountPercentage ? Number(convertFromPercentage(item.discountPercentage)) : 0,
        discountValue: item?.discountValue ?? 0,
        priceTotal: item?.priceTotal ?? 0,
        product: item?.product ?? null,
        productValue: item?.product ? getProductName(item.product) : "",
        isDiscountInPercentage: !item?.discountValue || !!item?.discountPercentage,
        priceBeforeDiscount: 0,
        isAlternative: item?.type===ItemType.TEXTUAL,
        parentItemId: isSubItem ? entity?.id : item?.parentItemId,
        vat: !!item?.vat ? Number(convertFromPercentage(item.vat)) : 0,
        priceTotalWithVat: item?.priceTotalWithVat ?? 0,
        vatValue: item?.vatValue ?? 0,
        category: item?.category ?? "",
        unit: item?.unit ?? TradeItemUnit.PIECES
    }), [entity]);

    const initValuesOffer = useCallback((item?: Partial<OfferItemResponse>, isSubItem?: boolean): OfferItemFormValues=>({
        ...commonInitValues(item, isSubItem),
        options: (item?.options ?? []).map((option)=>initValuesOffer(option, true)),
        description: item?.description ?? "",
        pricePerUnit: item?.pricePerUnit ?? 0
    }), [commonInitValues]);

    const initValuesBusinessCase = useCallback((item?: Partial<BusinessCaseItemResponse>, isSubItem?: boolean): BusinessCaseItemFormValues=>({
        ...commonInitValues(item, isSubItem),
        options: (item?.options ?? []).map((option)=>initValuesBusinessCase(option, true)),
        purchasePricePerUnit: item?.purchasePricePerUnit ?? 0,
        sellingPricePerUnit: item?.sellingPricePerUnit ?? clearNumberInput(),
        customerDescription: item?.customerDescription ?? "",
        invoiceDescription: item?.invoiceDescription ?? "",
        productDescription: item?.productDescription ?? "",
        isPurchaseDiscountInPercentage: !!item?.purchaseDiscountPercentage,
        purchaseDiscountValue: item?.purchaseDiscountValue ?? 0,
        purchaseDiscountPercentage: item?.purchaseDiscountPercentage
            ? Number(convertFromPercentage(item.purchaseDiscountPercentage))
            : 0,
        priceBeforePurchaseDiscount: 0,
        purchasePriceTotal: 0,
        deliveryNoteId: isSubItem ? null : (item?.deliveryNoteId ?? (deliveryNotes && deliveryNotes.length>0 ? deliveryNotes[0].id : ""))
    }), [commonInitValues]);

    const isSubItem = !!entity?.parentItemId || entity?.parentItemId===0;

    return (
        <Formik
            innerRef={formikRef}
            initialValues={isOffer ? initValuesOffer(entity) : initValuesBusinessCase(entity)}
            validationSchema={isOffer ? tradeItemSchemaObject : businessCaseItemSchemaObject}
            onSubmit={onSubmit}
        >
            {
                ({values})=>
                    <FormWithoutButton loading={loading} disabled={disabled}> {/* TODO prompt only when change */}
                        <TradeItemFormBase
                            isOffer={isOffer}
                            currenciesRates={currenciesRates}
                            currentCurrency={currentCurrency}
                            orderToSupplier={orderToSupplier}
                            purchaseCurrency={purchaseCurrency}
                            invoice={invoice}
                            disabled={disabled}
                            ignoreVat={ignoreVat}
                            deliveryNotes={deliveryNotes}
                        >
                            {
                                !orderToSupplier && !isSubItem &&
                                <>
                                    <FieldArray name="options">
                                        {
                                            ({push, remove})=>
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6">Podpoložky</Typography>
                                                    </Grid>
                                                    <SubItems item xs={12}>
                                                        {
                                                            values.options?.map((option, index)=>
                                                                <Box key={index}>
                                                                    {
                                                                        !disabled &&
                                                                            <Box sx={{mb: 1, textAlign: "right"}}>
                                                                                <Button
                                                                                    startIcon={<Delete />}
                                                                                    color="error"
                                                                                    onClick={()=>remove(index)}
                                                                                >
                                                                                    Smazat
                                                                                </Button>
                                                                            </Box>
                                                                    }
                                                                    <TradeItemFormBase
                                                                        isOffer={isOffer}
                                                                        name="options"
                                                                        index={index}
                                                                        currentCurrency={currentCurrency}
                                                                        currenciesRates={currenciesRates}
                                                                        purchaseCurrency={purchaseCurrency}
                                                                        orderToSupplier={orderToSupplier}
                                                                        invoice={invoice}
                                                                        disabled={disabled}
                                                                        ignoreVat={ignoreVat}
                                                                    />
                                                                    <SubItemDivider />
                                                                </Box>
                                                            )
                                                        }
                                                    </SubItems>
                                                    <ItemsTotal item xs={12}>
                                                        {
                                                            disabled
                                                                ? <Box />
                                                                : <Button
                                                                    startIcon={<Add />}
                                                                    onClick={()=>
                                                                        push(isOffer
                                                                            ? initValuesOffer({type: values.type}, true)
                                                                            : initValuesBusinessCase({type: values.type}, true)
                                                                        )
                                                                    }
                                                                >
                                                                    Přidat podpoložku
                                                                </Button>
                                                        }
                                                        <Box>
                                                            <span>Celkem podpoložky:</span> {priceWithCurrency(((): number=>{
                                                                let total: number = 0;
                                                                values.options?.forEach((option)=>total+=option.priceTotal);
                                                                return total;
                                                            })(), currentCurrency?.code)}
                                                        </Box>
                                                    </ItemsTotal>
                                                </>
                                        }
                                    </FieldArray>
                                </>
                            }
                        </TradeItemFormBase>
                    </FormWithoutButton>
            }
        </Formik>
    );
};
export default TradeItemForm;

interface ReturnProps extends OuterSubmitFormReturnProps<TradeItemFormValues>, LoadingFormReturnProps {
    props: Props;
}
interface Opt extends FormOpt<TradeItemFormValues, TradeItemResponse> {
    isOffer: boolean;
    currentCurrency: CurrencyResponse | undefined;
    purchaseCurrency: CurrencyResponse | undefined;
    currenciesRates: CurrencyExchangeRateResponse[];
    id?: number;
    businessCaseId?: number;
    orderToSupplier?: boolean;
    invoice?: boolean;
    order: number;
    offerId?: number;
    versionId?: number;
    ignoreVat?: boolean;
}
export function useTradeItemForm(
    {
        afterSave,
        isOffer,
        currenciesRates,
        currentCurrency,
        id,
        businessCaseId,
        order,
        orderToSupplier,
        purchaseCurrency,
        invoice,
        offerId,
        versionId,
        ignoreVat
    }: Opt
): ReturnProps {
    const caseManipulator = useBusinessCaseItemManipulator();
    const offerManipulator = useOfferItemManipulator();

    const getDataForItem = useCallback((values: TradeItemFormValues, itemOrder: number): TradeItemRequest => ({
        ...values,
        order: itemOrder,
        discountPercentage: values.isDiscountInPercentage
            ? convertToPercentage(values.discountPercentage ?? 0)
            : null,
        type: values.isAlternative ? ItemType.TEXTUAL : ItemType.STANDARD,
        vat: ignoreVat ? 0 : convertToPercentage(values.vat ?? 0),
        category: !values.category || values.category==="" ? undefined : values.category
    }), [ignoreVat]);

    const submitOffer = useCallback(async (values: OfferItemFormValues): Promise<boolean> => {
        if (!offerId || ! versionId) return false;

        const getItem = (itemValues: OfferItemFormValues, itemOrder: number): OfferItemRequest => ({
            ...getDataForItem(itemValues, itemOrder),
            options: itemValues.options?.map(getItem)
        });

        const data: OfferItemRequest = getItem(values, order);

        return await saveIdEntity(
            (id)=>offerManipulator.update.run({offerId, versionId, id, data}),
            ()=>offerManipulator.create.run({data, offerId, versionId}),
            caseManipulator.update.messageHandler.success,
            id, afterSave
        );
    }, [id, afterSave, order, versionId, offerId, getDataForItem]);

    const submitBusinessCase = useCallback(async (values: BusinessCaseItemFormValues): Promise<boolean> => {
        if (!businessCaseId) return false;

        const getItem = (itemValues: BusinessCaseItemFormValues, itemOrder: number): BusinessCaseItemRequest => ({
            ...getDataForItem(itemValues, itemOrder),
            options: itemValues.options?.map(getItem),
            purchaseDiscountPercentage: values.isPurchaseDiscountInPercentage
                ? convertToPercentage(values.purchaseDiscountPercentage ?? 0)
                : null
        });

        const data: BusinessCaseItemRequest = getItem(values, order);

        return await saveIdEntity(
            (id)=>caseManipulator.update.run({id, data, businessCaseId}),
            ()=>caseManipulator.create.run({businessCaseId, data}),
            caseManipulator.update.messageHandler.success,
            id, afterSave
        );
    }, [id, afterSave, businessCaseId, order, getDataForItem]);

    const onSubmit: OnSubmit<TradeItemFormValues> = useCallback( async (values, formikHelpers)=>{
        let result: boolean;
        if (isOffer) result = await submitOffer(values);
        else result = await submitBusinessCase(values);

        if (result) formikHelpers.resetForm({values});
    }, [submitBusinessCase, submitOffer, isOffer]);

    const loading = caseManipulator.loading || offerManipulator.loading;

    const {props} = useOuterSubmitForm(onSubmit, loading);

    return useMemo(()=>({
        props: {
            ...props,
            isOffer, currentCurrency, currenciesRates,
            orderToSupplier: !!orderToSupplier,
            invoice: !!invoice,
            purchaseCurrency
        },
        loading
    }), [props, isOffer, currenciesRates, currentCurrency, loading, orderToSupplier]);
}


const ItemsTotal = styled(Grid)(({theme})=>css`
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing(1)};
  font-weight: ${theme.typography.fontWeightBold};
  font-size: ${theme.typography.body2.fontSize};
  margin-top: -10px;
  color: ${theme.palette.grey[500]};
`);

const SubItems = styled(Grid)(({theme})=>css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(4)};
`);

const SubItemDivider = styled(Box)(({theme})=>css`
  background-color: ${theme.palette.grey[500]};
  position: relative;
  height: 2px;
  width: 100%;
  margin-top: ${theme.spacing(4)};
`);
